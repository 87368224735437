img {
  max-width: 100%;
}
.App {
  text-align: center;
}

.App-header {
  text-align: center;
  padding: 20px;
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
  box-shadow: 0px 1px 5px rgba(0,0,0,0.2); 
}

.logo {
  width: 100px;
  height: 75px;
  float: left;
}

.App-header p {
  margin: 10px 0;
}

.App-header small {
  font-size: 14px;
}

.header-button {
  margin: 10px;
}

.center-block{
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Cam-live {
  align-self: auto;
}

/* Home Page */
#homeButtons{
  margin: 25px;
}


.buttons-div {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 150px 0;
}
button.header-button, input.header-button, .header-button {
  background: #179ed8;
  color: #fff;
  padding: 10px 16px;
  font-size: 1rem;
  min-width: 150px;
  transition: all ease-in-out 0.3s 0s;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-weight: 500;
line-height: 1.75;
border-radius: 4px;
letter-spacing: 0.02857em;
text-transform: uppercase;
border: 0;
}
button.header-button:hover, input.header-button:hover, .header-button:hover{
  background: #0b709c;
  box-shadow:inherit;
}
.buttons-group-blog {
  text-align: center;
  padding: 15px;
  border: 1px solid #f1f1f1;
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  border-radius: 4px;
  transition: all ease-in-out 0.3s 0s;
  background: #f7f7f7;
}
.buttons-group-blog h3 {
  font-weight: bold;
  margin: 15px 0;
}
.buttons-group-blog:hover {
  box-shadow: inherit;
}

/* Login */
.login {
  text-align: center;
  max-width: 500px;
  margin: 50px auto;
  box-shadow: 0 0 20px rgba(0,0,0,.1);
  padding-bottom: 50px;
  }
.login img {
  max-width: 370px;
}
.login h1 {
  font-weight: bold;
  margin-bottom: 15px;
}
.login p {
  font-size: 18px;
  margin-bottom: 20px;
}
.login button {
  background: #179ed8 !important;
  color: #fff !important;
  font-size: 1rem !important;
  min-width: 150px;
  transition: all ease-in-out 0.3s 0s;
  justify-content: center;
}
.login button div {
  padding: 0 4px 3px 4px !important;
}

/* Instruction */
.instruction-page .container {
  padding-top: 50px;
  padding-bottom: 50px;
}
.instruction-page h2 {
  margin: 0 0 50px 0;
  text-align: center;
}
.instruction-page ul {
  font-size: large;
  padding-left: 15px;
}
.instruction-page ul li {
  padding-bottom: 10px;
  font-size: 16px;
  line-height: normal;
}
.instruction-page small {
  font-style: italic;
  color: #179ed8;
}

/* Thankyou */
.thankyouPage {
  text-align: center;
  max-width: 500px;
  margin: 50px auto;
  box-shadow: 0 0 20px rgba(0,0,0,.1);
  padding: 50px 0;
}
.thankyouPage img{ max-width: 200px;}
.thankyouPage h3 {
  font-size: 18px;
}
.thankyouPage h3 label {
  font-weight: bold;
}

/* Admin login */
.admin-login-form {
  width: 500px;
}
.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.admin-after-login { width: 500px; margin:50px auto; color: #000;}
.admin-after-login h1 {
  text-align: center;
  margin-bottom: 30px;
}
.admin-after-login h3 {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}
.admin-after-login .form-control {
  margin-bottom: 20px;
}
.admin-after-login .side {
  margin: 0 0 30px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.logout-btn {
  width: 150px;
  height: 48px;
  margin: 0 auto !important;
  display:block !important;
}
.adminsignin-wrapper, .alert-screen .container {
  width: 500px;
  max-width: 90%;
  margin: 50px auto;
}


@media(max-width:575px){
  .login, 
  .thankyouPage,
  .admin-login-form,
  .admin-after-login { max-width: 90%;}
}