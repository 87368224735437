.main {
    background-color: #fff;
    width: 700px;
    margin: 50px auto;
    border-radius: 1.5em;
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
    padding: 20px 20px 50px 20px; text-align: center;
}
.main ul {
    text-align: left;
  }
.sign {
    padding-top: 40px;
    padding-bottom: 40px;
    color:black;
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 30px;
}
table {
    text-align: left;
    margin-bottom: 50px;
  }
.test{
    color:black;
    padding-bottom: 10px;
    font-size: 24px;
}

#classIcon{
    max-width: 200px;
    max-height: 200px;
}


@media(max-width:767px){
    .main{ max-width: 90%;}
  }




    




