.questions
{
    background-color : off-white;
    border-left : 5px solid blue;
    width: 80%;
    margin: lg auto 0 auto;
    padding: sm md;

}
    .lifeline-container
    {
        display: flex;
        justify-content: space-between;
    }

    .lifeline
    {
        position : relative;
        top: -3px;
    }

    h4 
    {
        font-size: 1.2rem;
        margin-bottom: lg;
        line-height: 1.5;
        text-align: center;
    }







