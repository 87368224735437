.dashQuizContainer {
  padding: 20px;
}
.size{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  }
  .timer{
    position: fixed;
    top:55%;
    left:5%;
  }
  .row {
    display: flex;
  }
  
  /* Create two equal columns that sits next to each other */
  .column {
    flex: 50%;
    padding: 10px;
   
  }

 
  .quest {
    position:absolute;
    top: 0;
    right: 0;
    /* bring your own prefixes */
    /* transform: translate(-50%, -10%); */
  }

  .detect{
    height: 150px;
    width: 150px;
    position: relative;
  }

  /* .button{
    position:absolute;
    top: 80%;
    left: 10%;
  } */
  .candidateDetail h3 {
    font-size: 16px;
    font-weight: bold;
  }
  .candidateDetail h3 span {
    font-weight: normal; text-transform: capitalize;
  }
  .candidateDetail h3 label { min-width:100px;}
  .text{
    position:absolute;
    top:45%;
    left: 10%;
  }

  .leftClass {
    display: flex;
    text-align: center;
    background: #179ed8;
    padding: 10px;
    color: #fff;
    width: 170px;
    justify-content: center;
    border-radius: 10px;
  }

  .camContiner {
    display: flex;
    margin-bottom: 50px;
  }
  .detect {
    height: 150px;
    width: 150px;
    position: relative;
  }
  .candidateDetail {
    flex: 1;
    width: 100%;
    margin-left: 50px;
  }