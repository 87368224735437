.MainDiv {
    background-color: #282c34;
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: white;
  
  }

  .givecolor{
    color: powderblue;
  }


  .pool{
    color: white;
  }

  .center-block{
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .side{
    display: inline-block;
    margin: 10px;
  }