  video {
    max-width: 100%;
  }
  .validate-capture .container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .action-validate {
    text-align: center;
    padding-top: 30px;
  }
  .action-validate button {
    margin: 10px 15px;
  }